export const ARRAY_CONFIG:string = "ArrayConfig"; 

export const FIELD_CONFIG:string =  "FieldConfig";

export const IP_CONFIG:string = "IpConfig";

export const NUMBER_CONFIG:string = "NumberConfig";

export const PASSWORD_CONFIG:string = "PasswordConfig";

export const PATTERN_CONFIG:string = "PatternConfig";

export const RANGE_CONFIG:string = "RangeConfig"; 

export const RELATIONAL_OPERATOR_CONFIG:string = "RelationalOperatorConfig";