export const INVALID: string = "invalid";

export const PROPERTY: string = "property";

export const OBJECT_PROPERTY: string = "objectProperty";

export const ARRAY_PROPERTY: string = "arrayProperty"

export const STRING:string = "string";

export const MESSAGE:string = "message";

export const BLANK:string = "";

